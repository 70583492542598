<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <ChallengesList
            :headers="headersChallenges"
            :items="itemsChallenges"
            @initialize="initialize"
            :search_tool="true"
          ></ChallengesList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ChallengesList from "@/views/pages/challenges/ChallengesList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ChallengesList,
  },

  data: () => ({
    uri: "challenges",
    headersChallenges: [
      { text: "Estado", value: "status", sortable: false },
      { text: "Actualizado", value: "date_modified", sortable: false },
      { text: "Desafiante", value: "user_from_name", sortable: false },
      { text: "", value: "tags_from", sortable: false },
      { text: "Desafiado", value: "user_to_name", sortable: false },
      { text: "", value: "tags_to", sortable: false },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    itemsChallenges: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;

      setTimeout(() => {
        genericService.getChallenges(_self.currentUser.id ,function(records){
            _self.itemsChallenges = records
        });
      }, 400);

    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Desafíos" }]);
  },

  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
